const config = {
    MYSQL_SETTINGS:{
      host: 'localhost',
      user: 'sportdb2',
      password: 'p%WjGAq04G~tve~SIs1oZzL8',
      database: 'beta_db',
      logger: true,
    },
    SESSION_LIFETIME:  1000,
    secret: 'sup333rsecReTkEY42__makar!we@<3_YOU!!sp0rTv0kr!_@#@!@#&u&___g!',
    code: 'zpR5efQ-4d5ZLtWD1',

    PERMANENT_TOKEN: 'S9Z8EGGTVVtLggFTizCsMtwOJnRhjaQ2BMUQhcY',

    PORT: 50104,
    HOST: 'localhost',

    APIHOST: 'beta.sosportom.ru',
    GRAPHAPIHOST: 'betaapi.sosportom.ru/graphql',

    CORS_ALLOW: [
      'https://beta.sosportom.ru',
      'https://release.sosportom.ru',
      'https://www.sportvokrug.ru',
      'https://sportvokrug.ru',
    ],
};
module.exports = config;
